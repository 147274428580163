<template>
  <div
    class="this-or-that"
    data-test-id="this-or-that"
    :class="{ 'this-or-that--auth-wall' : showAuthWallCta }"
  >
    <div class="this-or-that__main-container">
      <div
        class="this-or-that__image-container"
      >
        <div
          ref="illustration"
          class="this-or-that__icon"
          :class="{ 'this-or-that__icon--animated': animationRunning }"
          v-html="svgMarkup"
        />
      </div>
      <engagement-result-auth-wall
        v-if="showAuthWallCta"
        :engagement-type="data.type"
      />
      <div
        v-else
        class="this-or-that__content-container"
      >
        <h2 class="this-or-that__heading" data-test-id="this-or-that-heading">
          This or That
        </h2>
        <p class="this-or-that__question" data-test-id="this-or-that-question">
          {{ data.question | decodeHtml }}
        </p>
        <this-or-that-results
          v-if="isAnswered"
          :options="engagement.options || []"
          :voted-answers="engagement.userAnswers"
        />
        <this-or-that-form
          v-else
          :options="data.options"
          :form-id="data.id"
          @optionSelected="refetchEngagement"
        />
      </div>
    </div>
    <app-link
      v-if="ctaLink && isAnswered && !resultsRequireAuth"
      class="this-or-that__cta-link link--unstyled"
      data-test-id="this-or-that-cta-link"
      :to="ctaLink"
    >
      {{ ctaText }}
      <icon-component
        name="caret"
        title="caret icon"
        class="this-or-that__cta-icon"
      />
    </app-link>
  </div>
</template>

<script>
import ThisOrThatForm from '@/components/EngagementToolkit/ThisOrThatForm.vue';
import ThisOrThatResults from '@/components/EngagementToolkit/ThisOrThatResults.vue';
import EngagementResultAuthWall from '@/components/EngagementToolkit/EngagementResultAuthWall.vue';
import { GET_ENGAGEMENT } from '@/graphql/queries/engagement-toolkit-queries';
import { mapGetters } from 'vuex';
import engagementToolkitMixin from '@/mixins/engagement-toolkit-mixin';

export default {
  name: 'ThisOrThat',
  components: {
    ThisOrThatForm,
    ThisOrThatResults,
    EngagementResultAuthWall,
  },
  mixins: [engagementToolkitMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      engagement: {},
      iconName: 'this-or-that',
    };
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
    ]),
    resultsRequireAuth() {
      return this.engagement?.resultsRequireAuth && !this.userIsLoggedIn;
    },
    selectedOption() {
      if (this.engagement?.userAnswers?.length > 0) {
        const userAnswer = this.engagement.userAnswers[0];
        return this.engagement.options.find((option) => option.id === userAnswer) || null;
      }
      return null;
    },
    ctaLink() {
      return this.selectedOption?.ctaLink || this.engagement.ctaLink || '';
    },
    ctaText() {
      return this.selectedOption?.ctaText || this.engagement.ctaText || 'Read More';
    },
    showAuthWallCta() {
      return this.isAnswered && this.resultsRequireAuth;
    },
    isAnswered() {
      return this.engagement?.userAnswers?.length > 0;
    },
  },
  methods: {
    refetchEngagement() {
      this.$apollo.queries.engagement.refetch();
    },
  },
  apollo: {
    // We need to make a query to get the engagement
    // userAnswers to keep track of if the user has
    // already answered the thisOrThat so we can display
    // results instead of asking the same question.
    engagement: {
      query: GET_ENGAGEMENT,
      variables() {
        return {
          id: this.data.isCurrent ? null : this.data.id,
          type: this.data.type,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_this-or-that';
</style>

<style lang="scss"> // no scoped to reach the svg elements within v-html
  @import '@/stylesheets/components/_this-or-that-icon'; // this-or-that.svg's animation
</style>
